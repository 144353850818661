.menu {
    background-color: rgba(0,0,0,0.6);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2em;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;

        li {
            margin: 0 2em;
            flex-basis: 20%;
            text-align: center;

            a {
                color: #ffffff;
                text-decoration: none;

                &.active {
                    color: #55C6D7;
                    border-bottom: 3px solid #55C6D7;
                }
            }
        }

        @media(max-width: 767px) {
            overflow-x: scroll;
            justify-content: flex-start;
        }
    }
}