body {
    background-color: #08395B;
    color: #ffffff;

    .App {
        height: 100vh;
        overflow: hidden;

        @media (max-width: 767px) {
            overflow: auto;
        }

        .btn {
            background-color: #55C6D7;
            color: #0E1724;
            padding: 5px 30px;
            border-radius: 0;
        }

        .m-3 {
            margin: 6em 0;
        }

        .m-5 {
            margin: 10em 0;
        }
    }
    
}